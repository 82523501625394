.content-item {
  padding: 0 0em 0 0.5em !important;
  position: relative;
}

.content-item-link {
  position: relative;
  display: block;
  border: none;
  outline: none;
  &:not(.influence-list-item) {
    &:focus {
      border: none;
    }
  }
  &:focus {
    outline: none;
  }
  .button-thumbs-down {
    margin-right: 4px;
  }
}

.parliament-live-item-link {
  .parliamentary-event-icon {
    opacity: 0;
    transition: 0.2s ease-in;
  }
  &:hover {
    .tag-keyword-list-watch {
      background-color: $light-blue;
      color: $main-white;
    }
    .parliamentary-event-icon {
      opacity: 1;
    }
  }
  .content-title {
    .title-body {
      -webkit-line-clamp: 1;
      min-height: 22px;
    }
  }
}

.parliament-event-live-text {
  .policymogul-keyword-match {
    padding: 0px;
  }
}

.parliament-live-item-link-hover {
  .tag-keyword-list-watch {
    background-color: $main-white !important;
    color: $light-blue !important;
  }
}

.content-item-title {
  color: #022344;
  font-size: 0.95rem;
  font-weight: 600;
  @extend .hidden-lines;
  -webkit-line-clamp: 3;
}

.stakeholder-list-item {
  padding-left: 0em !important;
}

.relevant-remark-item {
  padding-left: 1.8em !important;
  padding-right: 1.8em !important;
  .title-body-container {
    position: relative;
    &::before,
    &:after {
      content: '';
      width: 16px;
      height: 16px;
      position: absolute;
    }
    &::before {
      left: -24px;
      background: url('#{$cdn-url}quote_open_black.png') center / contain no-repeat;
    }
    &::after {
      right: -16px;
      bottom: 0px;
      background: url('#{$cdn-url}quote_close_black.png') center / contain no-repeat;
    }
  }
}

@media screen and (min-width: 992px) {
  .relevant-remark-item {
    padding-left: 2.3em !important;
    padding-right: 2.3em !important;
    .title-body-container {
      &::before,
      &:after {
        width: 20px;
        height: 20px;
      }
      &::before {
        left: -30px;
      }
      &::after {
        right: -24px;
        bottom: -5px;
      }
    }
  }
}
