@import 'styles/mixins.scss';
@import url('https://cdn.policymogul.com/fonts/20250323-1/style.css');
@import 'styles/variables.scss';
@import 'bootstrap/scss/bootstrap.scss';
@import 'hamburgers/_sass/hamburgers/hamburgers.scss';
@import 'react-notifications-component/dist/scss/notification.scss';
@import 'react-loading-skeleton/dist/skeleton.css';
@import url('https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,300;0,400;0,700;0,900;1,400&family=Work+Sans:wght@300&display=swap');
@import url('https://fonts.googleapis.com/css?family=Montserrat:400,400i,500,500i,600,600i,700,800&display=swap');
@import 'styles/Dashboard/Dashboard.scss';
@import 'styles/Login/login.scss';
@import 'styles/Base/base.scss';
@import 'styles/Influence/influence.scss';
@import 'styles/Settings/settings.scss';
@import 'styles/Embed/embed.scss';
@import 'styles/Admin/index.scss';
@import 'styles/SavedItems/index.scss';
@import 'styles/Analytics/analytics.scss';
@import 'styles/Reports/reports.scss';
@import 'styles/Wordpress/wordpress.scss';
