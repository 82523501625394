@use 'sass:math';
* {
  box-sizing: border-box !important;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  -moz-tap-highlight-color: rgba(0, 0, 0, 0);
}

body {
  font-family: 'Lato', sans-serif;
  background: #f9f9f9;
  overflow: hidden;
  font-size: 100%;
}

.dashboard-root-container {
  height: 100%;
  display: flex;
  flex-direction: column;
}

.search-container {
  position: relative;
  padding: 10px 0px;
  display: flex;
  justify-content: flex-end;
  .search-tabs {
    position: relative;
  }
  .search {
    margin: 0 !important;
  }
}

/* .main-content{
  background: rgba(0, 148, 204, 0.04);
}  */

.section-title {
  border-bottom: 0.5px solid #ececec;
}

.team-list-item {
  .team-title-link {
    color: inherit;
    &:hover {
      text-decoration: none;
    }
  }
  &:hover {
    .team-title-link {
      color: #0094cc;
      cursor: pointer;
    }
  }
}

.team-link {
  color: unset !important;
  &:hover {
    text-decoration: none;
  }
}

.section-title-mapping {
  padding-bottom: 6px;
  border-bottom: 1px solid #d4d4d4;
}

.background-white {
  background-color: #ffffff !important;
}

.tailored-Consultations-list {
  li {
    list-style-type: none;
  }
}

.select-section {
  -moz-appearance: none;
  font-size: 14px;
  height: 28px;
  margin-top: 7px;
  color: #022344;
  select {
    z-index: 2;
  }
}

.select-section-wrapper {
  position: relative;
}

.dot {
  background: #848484;
  width: 4px;
  min-width: 4px;
  min-height: 4px;
  height: 4px;
  margin: 0 8px;
  border-radius: 50%;
}

.scroll-container {
  overflow-y: auto;
  scrollbar-color: rgba(2, 35, 68, 0.2);
  scrollbar-width: thin;
  &::-webkit-scrollbar {
    -webkit-appearance: none;
    width: 6px;
  }
  &::-webkit-scrollbar-thumb {
    border-radius: 8px;
    background: rgba(#99a4ae, 0.7);
    &:hover {
      background-color: #99a4ae;
    }
  }
}

.embed-list {
  & > div {
    overflow: visible !important;
  }
}
.embed-padding,
.simple-scrollbar .embed-padding {
  .ScrollbarsCustom-TrackY {
    width: 6px !important;
    right: -10px;
  }
}
.normalized-scroll-container {
  overflow-y: auto;
  &::-webkit-scrollbar {
    display: none !important;
  }
}

.main-container-sections {
  width: calc(100% + 24px);
  padding-right: 0.65em;
  padding-left: 0.5em;
  margin-left: -0.5em;
}

.policy-list {
  list-style: none;
}

.stakeholders-only-banner {
  background: #f7fcfe;
  border: 1px solid #d4d4d4;
}

.share-buttons-modal {
  button:disabled {
    background-color: rgba($main-blue, 0.05) !important;
    color: rgba(0, 28, 53, 0.7) !important;
    cursor: default;
    border: 1px transparent !important;
  }
  button {
    border: 1px transparent;
    cursor: pointer;
  }
}

.control-buttons {
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
  border-radius: 8px;
  background: #fff;
  button {
    padding: 2px 0px;
    border: none;
    outline: none;
    font-size: 0.9em;
    font-weight: 700;
    border-radius: 5px;
    transition: 0.2s ease-in;
    background: #fff;
    color: rgba(0, 28, 53, 0.6);
  }
  .active {
    background: $hover-blue;
    color: #fff;
  }
}

.no-results-message {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  p {
    text-align: center;
    margin-bottom: 0;
    font-weight: 700;
    color: #4a4a4a;
  }
}

.main-title {
  font-weight: 700;
  line-height: 1;
  //padding-left: 0.95rem;
  font-size: 1.125rem;
  position: relative;
  /* &::before{
    content: '';
    height: calc(100% - 7px); //85%;
    top: calc(2% + 3px); //8%;
    left: 0;
    position: absolute;
    width:5px;
    background-color: $hover-blue;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  } */
  span {
    font-weight: 400;
  }
}

.adjustment-main-title {
  padding-top: 0.5em;
}

.showing-results {
  color: rgba(0, 18, 43, 0.6);
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 120px;
  line-height: 1.15;
  vertical-align: middle;
  margin-top: -4px;
  display: none;
}

.section-total-results {
  color: rgba(0, 18, 43, 0.55);
  font-size: 14px;
  padding-left: 5px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 100%;
  .search-item-name {
    color: rgba(0, 18, 43, 0.8);
  }
}

.scroll-responsive {
  overflow-y: auto !important;
  overflow-x: hidden !important;
}

.content-tags {
  p {
    display: inline-block;
    font-size: 0.85em;
    background-color: #ececec;
    border-radius: 3px;
  }
}

.box {
  background: #ffffff;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
}

.box-hover {
  position: relative;
  transition: transform 0.2s ease-in;
  @extend .box;
  &:hover {
    transform: scale(0.98);
    &::after {
      content: '';
      position: absolute;
      height: 100%;
      width: 5px;
      background-color: #9c9c9c;
      right: 0;
      top: 0;
      border-top-right-radius: 16px;
      border-bottom-right-radius: 16px;
    }
  }
}

.general-button {
  background: none;
  outline: none;
  border: none;
  padding: 0;
  transition: 0.2s ease-in;
  color: $main-blue;
  &:focus {
    outline: none;
  }
  &:active {
    outline: none;
  }
}

.hover-background-gray-05 {
  &:hover {
    background-color: rgba(212, 212, 212, 0.5);
  }
}

.gray-outlined-button {
  background-color: #fff;
  border: 1px solid rgba(212, 212, 212, 1);
  padding: 3px 22px !important;
  border-radius: 3px;
  transition: all 200ms;
  &:hover {
    background: rgba($main-blue, 0.05);
  }
}

.active-outlined-button {
  @extend .action-button;
  background-color: #fff !important;
  border: 1px solid #0094cc !important;
  color: #0094cc !important;
  &:hover {
    background: rgba(0, 148, 204, 0.04) !important;
  }
}

.transition-200 {
  transition: all 200ms;
}

.bordered-button {
  border: 1px solid #d4d4d4;
  border-radius: 20px;
  font-weight: 400;
  font-size: 15px;
  line-height: 18px;
  color: rgba(0, 18, 43, 0.7);
  padding: 10px 20px !important;
  white-space: nowrap;
  margin: 0 !important;
  background-color: #fff !important;
}

.clear-all-button {
  max-width: 99px !important;
}

.main-button {
  background: $main-yellow;
  border-radius: 5px;
  color: #fff;
  border: 1px solid $main-yellow;
  &:disabled {
    background-color: rgba($main-grey, 0.2) !important;
    border: 1px solid $main-grey;
    color: rgba($main-blue, 0.6);
  }
}

.content-container {
  background: #ffffff;
}

.content-sticky {
  position: -webkit-sticky;
  position: sticky;
  top: 0;
}

mark {
  padding: 0.1rem 0;
  border-radius: 3px;
  background-color: #fae1a8;
}
.container-wrapper {
  max-width: 1600px;
}

.main-content {
  width: 100%;
}

.main-content-wrapper {
  max-width: 1440px;
  width: 100%;
}

.main-content-wrapper-content {
  background: #fff;
}

.logged-headline {
  background: $hover-blue;
  color: #fff;
  margin-bottom: 0;
  border-radius: 3px;
  padding: 0.65rem 0.5rem;
  font-size: 1em;
  cursor: pointer;
  button {
    background: #ffffff;
    box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
    border-radius: 5px;
    color: #00122b;
    font-weight: bold;
    font-size: 0.85em;
    padding-top: 0.2rem;
    padding-bottom: 0.2rem;
  }
}

.show-lg {
  display: none;
}

.total-height {
  height: 100%;
}

.resources-space {
  height: 1.25rem;
}

.pointer {
  cursor: pointer;
}

.default-cursor {
  cursor: default !important;
}

.cursor-text {
  cursor: text;
}

.image-render-optimized {
  image-rendering: -webkit-optimize-contrast;
}

.policymogul-keyword-match {
  background-color: rgba(#f2a805, 0.25);
  padding: 1px;
  font-style: normal;
}

.content-lists-container {
  padding-top: 1.65em;
}

.influence-lists-container.main-sticky {
  z-index: 100;
}

.content-lists-title {
  padding-left: 5px;
  .main-title {
    min-width: 190px;
  }
}

.action-button {
  background-color: $hover-blue;
  color: #fff;
  border-radius: 5px;
  padding-top: 0.35rem;
  padding-bottom: 0.35rem;
  transition: background-color 0.2s ease-in;
  &:hover {
    text-decoration: none;
    color: #fff;
    background-color: rgba($hover-blue, 0.8);
  }
}

.unseen-position {
  position: absolute;
  z-index: 100;
}

.unseen-dot {
  @extend .unseen-position;
  background-color: #f2a805;
  top: 28px;
  left: 13px;
  width: 7px;
  height: 7px;
  border-radius: 50%;
  cursor: pointer;
}
.unseen-dot-hover {
  @extend .unseen-position;
  background: url('#{$cdn-url}circle-tick-white.svg') center / contain no-repeat;
  width: 21px;
  height: 21px;
  top: 21px;
  left: 5px;
  z-index: 100;
  display: none;
  cursor: pointer;
}

.adjustment-unseen-dot {
  .unseen-dot {
    top: 21px;
  }
  .unseen-dot-hover {
    top: 14px;
  }
}

.adjustment-unseen-dot-parliamentary {
  .unseen-dot {
    top: 29px;
  }
  .unseen-dot-hover {
    top: 22px;
  }
}

.calendar-unseen {
  .unseen-dot {
    top: 23px;
    left: -18px;
  }
  .unseen-dot-hover {
    top: 16px;
    left: -26px;
  }
}

.consultation-unseen {
  &::before {
    //top: 31px!important;
    //left:12px;
  }
}

.link-text {
  cursor: pointer;
  text-decoration: underline;
  &:hover {
    text-decoration: underline;
  }
}

.simple-link-text {
  cursor: pointer;
  color: #0094cc !important;
  &:hover {
    color: #0094cc !important;
    text-decoration: underline;
  }
}

.text-dark-red {
  color: #9b171f !important;
}

.light-blue-background-08-hover {
  &:hover {
    background-color: rgba(#0094cc, 0.8) !important;
  }
}
.light-blue-background-004-hover {
  &:hover {
    background-color: rgba(#0094cc, 0.04) !important;
  }
}
.parliamentary-content-in,
.main-update-content-container {
  padding-top: 10px;
  .section-title,
  .general-feedback-container {
    user-select: none;
  }
  .icon-link {
    display: flex;
    align-items: center;
    font-family: 'Lato' !important;
    margin-bottom: 15px;
    .svg-inline--fa {
      width: 13px;
      margin-right: 7px;
    }
  }
}

.general-content-column {
  width: 100%;
}

.react-notification-root {
  z-index: 10000000000;
}

.notification-item {
  border-left: none;
  border-radius: 5px;
  .notification-message {
    padding: 6px 7px;
    font-size: 17px;
    a {
      color: $main-white;
      text-decoration: underline;
    }
  }
  .notification-close {
    width: 20px;
    height: 20px;
    background-color: rgba(0, 0, 0, 0.05);
    border-radius: 2px;
    top: 2px;
    right: 2px;
    &::after {
      content: '×';
      font-weight: bold;
      font-size: 20px;
      top: 8px;
      text-align: center;
    }
  }
}

.notification-danger {
  background-color: #ce352c;
}

.notification-success {
  background-color: #60a917;
}

.no-select-item {
  user-select: none;
}

.flex-centered {
  display: flex;
  align-items: center;
}

.section-maintitle-container {
  min-height: 24px;
  margin-bottom: 1.2em;
  margin-top: -8px;
  //AE: SPACE OF THE TEXT ON MOBILE WHEN THE ITEM WRAPS
  .total-results-container,
  .main-title {
    line-height: 1;
    margin-top: 8px !important;
  }
}

.section-maintitle-nolists {
  min-height: 0px;
}

.influence-page-scrollbar {
  .ScrollbarsCustom-Content {
    height: auto !important;
  }
}

.general-close-background {
  width: 100%;
  height: 100%;
  background: transparent;
  position: fixed;
  top: 0;
  left: 0;
}

.outline-none {
  outline: none;
}

.actions-create-report-button {
  border-radius: 0px 50rem 50rem 0px;
}
.content-tooltip-actions {
  padding: 5px;
  &::after {
    content: '';
    border-left: 7px solid transparent;
    border-right: 7px solid transparent;
    border-top: 8px solid $light-blue;
    position: absolute;
    margin: auto;
    left: 0;
    right: 0;
    bottom: -9px;
    width: 9px;
    height: 9px;
  }
  button {
    &:hover {
      color: $light-blue !important;
    }
  }
  .actions-create-report-button {
    &:hover {
      border-radius: 50rem;
      border-left-color: transparent;
      background-color: $main-white !important;
    }
  }
}

.tooltip-actions-bottom {
  &::after {
    border-top: none;
    border-bottom: 8px solid $light-blue;
    border-left: 7px solid transparent;
    border-right: 7px solid transparent;
    bottom: 42px;
  }
}

.danger-button {
  background: $danger-background;
  border-radius: 5px;
  color: #fff;
  transition: all 200ms;
  border: none;
  &:hover {
    background: rgba($danger-background, 0.8);
  }
  &:disabled {
    color: $main-white !important;
    background: rgba($danger-background, 0.6);
  }
}
.danger-button-outlined {
  background: #ffffff;
  border: 1px solid rgba($danger-background, 0.9);
  border-radius: 5px;
  color: rgba($danger-background, 0.9);
  transition: all 200ms;
  &:hover {
    background: #f5fbfd;
  }
}

.subtitle-text {
  color: rgba($main-blue, 0.6);
}

.color-main-yellow {
  color: $main-yellow !important;
}

.overscroll-behavior-contain {
  overscroll-behavior: contain;
}

@media screen and (min-width: 420px) {
  .showing-results {
    display: inline-block;
  }
}

@media screen and (min-width: 576px) {
  .showing-results {
    max-width: 350px;
  }

  .logged-headline {
    padding: 0.65rem 1.25rem;
    button {
      font-size: 0.95em;
    }
  }
}

@media screen and (min-width: 992px) {
  .section-maintitle-container {
    margin-bottom: 0.5em;
  }

  .main-content-wrapper-content {
    background: transparent;
  }

  .main-update-content-container {
    padding-top: 0px;
  }

  .content-lists-container {
    padding-top: 2.0625em;
  }

  .influence-lists-container.main-sticky {
    padding-top: 0;
    z-index: 100;
  }

  .showing-results {
    max-width: 100px;
  }
  .content-container {
    position: relative;
    border: 1px solid rgba(212, 212, 212, 0.6);
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.15);
    border-top: 0;
    border-radius: 5px;
    &::before {
      content: '';
      position: absolute;
      top: 0;
      left: 0px;
      width: 100%;
      border-radius: 5px 5px 0 0;
      border-top: 1px solid rgba(212, 212, 212, 0.6);
      z-index: 1000;
    }
  }
  .main-container-sections {
    width: calc(100% + 32px);
    padding-right: 1.21em;
    padding-left: 0.5em;
    margin-left: -0.5em;
  }
  .scroll-responsive {
    overflow-y: hidden !important;
  }
  .hide-lg {
    display: none !important;
  }
  .show-lg {
    display: block;
  }
  .container-wrapper {
    padding-left: 3rem !important;
  }
  .control-buttons-container {
    padding-right: 1.35rem;
  }

  .side-left-element {
    width: 280px;
  }

  .main-content {
    width: calc(100% - 280px);
  }

  .main-content-influence {
    width: 100%;
  }

  .general-content-column {
    flex: 0 0 68%;
    max-width: 68%;
  }

  .related-content-column {
    flex: 0 0 30%;
    max-width: 30%;
  }

  .main-button {
    &:hover {
      background-color: rgba($main-yellow, 0.8);
    }
  }
}
.crm-contacts-select-subnav {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  animation-name: crm-contacts-select-subnav-animation;
  animation-duration: 0.3s;
}

.background-medium-sea-green {
  background: $medium-sea-green;
}
.danger-background {
  background: $danger-background;
}

.max-width-none {
  max-width: none !important;
}

@keyframes crm-contacts-select-subnav-animation {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@for $i from 1 through 9 {
  .line-height-1-#{$i} {
    line-height: math.div(10 + $i, 10);
  }
}

.visibility-hidden {
  visibility: hidden;
}

@media screen and (max-width: 992px) {
  .stakeholder-sub-nav {
    justify-content: space-between;
    & .stakeholder-action-btn-container {
      display: flex;
      align-items: center;
      margin-left: 8px;
    }
    & .stakeholder-search-bar-container {
      margin-left: auto;
    }
  }
}

@media screen and (min-width: 992px) {
  .stakeholder-sub-nav {
    & .stakeholder-action-btn-container {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-left: 18px;
    }
  }
}

.stakeholder-search-bar-container {
  display: flex;
  align-items: center;
  flex-grow: 1;
}

@media screen and (max-width: 992px) {
  .stakeholder-search-bar-container {
    justify-content: flex-end;
  }
}

@media screen and (min-width: 1100px) {
  .showing-results {
    max-width: 200px;
  }
}

@media screen and (min-width: 1280px) {
  .showing-results {
    max-width: 350px;
  }
  /*.main-content-wrapper-content{
    padding-left: 42px;
  }*/
}
