/**************************\
  Basic Modal Styles
\**************************/
.modal {
  z-index: 10001;
}
.modal__overlay {
  z-index: 1000;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(2, 35, 68, 0.45);
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal__container {
  position: relative;
  background-color: #fff;
  max-width: 700px;
  width: 90%;
  max-height: 94%;
  // max-height: 100vh;
  border-radius: 8px;
  overflow-y: auto;
  box-sizing: border-box;
}

.modal__header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.modal__title {
  margin-top: 0;
  margin-bottom: 0;
  font-weight: 600;
  font-size: 1.25rem;
  line-height: 1.25;
  color: #00449e;
  box-sizing: border-box;
}

.modal__close {
  background: transparent;
  border: 0;
}

.modal__header .modal__close:before {
  content: '\2715';
}

.modal__content {
  margin-top: 2rem;
  margin-bottom: 2rem;
  line-height: 1.5;
  color: rgba(0, 0, 0, 0.8);
}

.modal__btn {
  font-size: 0.875rem;
  padding-left: 1rem;
  padding-right: 1rem;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  background-color: #e6e6e6;
  color: rgba(0, 0, 0, 0.8);
  border-radius: 0.25rem;
  border-style: none;
  border-width: 0;
  cursor: pointer;
  -webkit-appearance: button;
  text-transform: none;
  overflow: visible;
  line-height: 1.15;
  margin: 0;
  will-change: transform;
  -moz-osx-font-smoothing: grayscale;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  -webkit-transform: translateZ(0);
  transform: translateZ(0);
  transition: -webkit-transform 0.25s ease-out;
  transition: transform 0.25s ease-out;
  transition:
    transform 0.25s ease-out,
    -webkit-transform 0.25s ease-out;
}

.modal__btn:focus,
.modal__btn:hover {
  -webkit-transform: scale(1.05);
  transform: scale(1.05);
}

.modal__btn-primary {
  background-color: #00449e;
  color: #fff;
}

.btn-preregister-link {
  display: block;
  margin: 0.75rem auto;
}

/**************************\
    Demo Animation Style
  \**************************/
@keyframes mmfadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes mmfadeOut {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}

@keyframes mmslideIn {
  from {
    transform: translateY(15%);
  }
  to {
    transform: translateY(0);
  }
}

@keyframes mmslideOut {
  from {
    transform: translateY(0);
  }
  to {
    transform: translateY(-10%);
  }
}

.micromodal-slide {
  display: none;
}

.micromodal-slide.is-open,
.micromodal-slide-unseen.is-open {
  display: block;
}

.micromodal-slide[aria-hidden='false'] .modal__overlay {
  animation: mmfadeIn 0.3s cubic-bezier(0, 0, 0.2, 1);
}

.micromodal-slide[aria-hidden='false'] .modal__container {
  animation: mmslideIn 0.3s cubic-bezier(0, 0, 0.2, 1);
}

.micromodal-slide[aria-hidden='true'] .modal__overlay {
  animation: mmfadeOut 0.3s cubic-bezier(0, 0, 0.2, 1);
}

.micromodal-slide[aria-hidden='true'] .modal__container {
  animation: mmslideOut 0.3s cubic-bezier(0, 0, 0.2, 1);
}

.micromodal-slide .modal__container,
.micromodal-slide .modal__overlay {
  will-change: auto;
}

@media only screen and (min-width: 576px) {
  ._2p3a {
    width: 100px !important;
  }
  .btn-preregister-link {
    margin: 0;
  }
}

.micromodal-slide {
  font-size: 1.03em;
  h2 {
    color: $medium-blue;
    font-size: 1.2em;
    font-weight: 700;
  }
}

.micromodal-title {
  p {
    color: rgba(0, 28, 53, 0.7);
  }
}

.preregister-check {
  width: 26px;
  img {
    margin-top: 0.3rem;
  }
}

.preregister-description {
  width: calc(100% - 42px);
  color: $header-blue;
  .title-description {
    font-size: 1.05rem;
  }
  p {
    margin-bottom: 0;
  }
}

.preregister-action {
  font-size: 1rem;
  background: rgba(233, 233, 233, 0.6);
  border-radius: 0px 0px 8px 8px;
}

.btn-preregister {
  background: $hover-blue;
  border-radius: 8px;
  font-size: 1.2rem;
  color: #fff;
  font-weight: 700;
  transition: 0.2s ease-in;
  &:hover {
    transform: scale(1.05);
  }
}

.preregister-close {
  position: absolute;
  border-radius: 8px;
  top: 0.75rem;
  right: 0.75rem;
  cursor: pointer;
  transition: 0.2s ease-in;
  z-index: 100;
  img {
    margin-top: -2px;
    width: 14px;
  }
  &:hover {
    background: rgba(132, 132, 132, 0.16);
  }
}

.close-button-filters {
  position: absolute;
  top: 5px;
  right: 5px;
  text-indent: -1000px;
  overflow: hidden;
  background: url('#{$cdn-url}close-icon.svg') center 53% / 12px auto no-repeat;
  width: 35px;
  height: 35px;
  border-radius: 50%;
  transition: 0.2s ease-in;
  z-index: 1000;
  &:hover {
    background-color: rgba(0, 18, 43, 0.1);
  }
}
