@each $name, $title-size in $title-sizes {
  $result: calc($title-size / $default-font-size);
  .title-#{$name} {
    font-size: $result + rem;
  }
}

@each $name, $title-size in $title-sizes {
  .title-#{$name}-bold {
    @extend .title-#{$name};
    font-weight: 700;
    line-height: 1.35;
    span {
      font-weight: 400;
    }
  }
}

@each $name, $paragraph-size in $paragraph-sizes {
  $result: calc($paragraph-size / $default-font-size);
  .paragraph-#{$name} {
    font-size: $result + rem;
  }
}

@each $name, $paragraph-size in $paragraph-sizes {
  .paragraph-#{$name}-bold {
    @extend .paragraph-#{$name};
    font-weight: 700;
  }
}

.item-size-text {
  font-size: 0.9em;
}

.blue-text {
  color: $light-blue;
}

.main-dark-text {
  color: rgba($main-blue, 1);
}

.subtle-text {
  color: rgba($main-blue, 0.8) !important;
}

.main-disabled-text {
  color: rgba($main-blue, 0.4) !important;
}

.main-light-text {
  color: rgba($main-blue, 0.6);
  &:hover {
    color: rgba($main-blue, 0.6);
  }
}

$opacity-values: 10, 15, 20, 25, 30, 35, 40, 45, 50, 55, 60, 65, 70, 75, 80, 85, 90, 95, 100;

@each $value in $opacity-values {
  $opacity: calc($value / 100);
  .light-#{$value}-text {
    color: rgba($main-blue, $opacity);
    &:hover {
      color: rgba($main-blue, $opacity);
    }
  }
}

@for $i from 10 through 100 {
  .centered-#{$i} {
    margin-top: -#{$i}px;
  }
}

.light-50-text {
  color: rgba($main-blue, 0.5);
  &:hover {
    color: rgba($main-blue, 0.5);
  }
}

.light-70-text {
  color: rgba($main-blue, 0.7);
  &:hover {
    color: rgba($main-blue, 0.7);
  }
}

.emphasis-item {
  color: $light-blue;
}

.main-link {
  color: $light-blue;
  &:hover {
    color: $light-blue;
    text-decoration: none;
  }
}

.main-subtle-text {
  color: rgba($main-blue, 0.8);
  &:hover {
    color: rgba($main-blue, 0.8);
  }
}

.content-text-color {
  color: rgba($main-blue, 0.85);
}

.adjust-line-height {
  line-height: 1.3em;
}

.small-line-height {
  line-height: 1.3;
}

.text-underline {
  text-decoration: underline;
}

.reset-link {
  &:hover {
    text-decoration: none;
  }
}
.reset-link-color {
  &:hover {
    color: $main-blue;
  }
}

.general-link {
  &:hover {
    color: $light-blue;
    text-decoration: none;
  }
}

.link-hover {
  &:hover {
    color: $light-blue;
  }
}

.text-decoration-none {
  text-decoration: none;
  &:hover {
    text-decoration: none;
  }
}

.text-underline {
  text-decoration: underline;
}

.text-925 {
  font-size: 0.925em;
  line-height: 1.4;
}

.break-word {
  word-wrap: break-word;
  word-break: break-word;
  hyphens: auto;
}

@media screen and (min-width: 992px) {
  .text-underline-hover {
    &:hover {
      text-decoration: underline;
    }
  }
}
