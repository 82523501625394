header {
  background-color: #fff;
}

.mobile-loggedout-header {
  background: $dark-blue !important;
  .hamburger {
    margin-top: 2px;
  }
  .logo {
    margin-left: -11px;
  }
}

.main-header {
  background: $dark-blue;
  width: 100%;
  z-index: 1003;
  border-bottom: none;
  padding-top: 13px;
  padding-bottom: 13px;
  .nav-pages a,
  .btn-login,
  .static-page-link {
    color: #fff;
  }
  .searchbar-header {
    width: 100%;
  }
  .searchbar-header {
    background: #fff;
    input {
      background: #fff;
    }
  }
  .nav-pages {
    .static-page-link,
    .more-navigation-element {
      padding: 0px 24px !important;
    }

    .active-page {
      color: $dark-mustard !important;
      position: relative;
      &::after {
        content: '';
        border-left: 7px solid transparent;
        border-right: 7px solid transparent;
        border-bottom: 8px solid transparent; //white
        position: absolute;
        margin: auto;
        left: 0;
        right: 0;
        bottom: -20.5px;
        width: 9px;
        height: 9px;
      }
      &:hover {
        color: $dark-mustard !important;
      }
    }
    .election-subnav-link {
      &::after {
        @media screen and (min-width: 992px) {
          border-bottom: 8px solid #7babea;
        }
      }
    }
    .election-subnav-link {
      &.dark {
        &::after {
          @media screen and (min-width: 992px) {
            border-bottom: 8px solid #605064;
          }
        }
      }
    }
    .active-page-active-subnav {
      color: $dark-mustard !important;
      &::after {
        transition: all 200ms;
        border-bottom: 8px solid $hover-blue;
      }
    }
    .active-page.open {
      &::after {
        border-top: none !important;
        border-bottom: 6px solid $dark-mustard !important;
      }
    }
  }
  .search-message {
    display: none;
  }

  .navigation-container-mobile-logged-out {
    .btn-login {
      color: $secondary-blue !important;
    }
  }

  .agency-clients-navigation {
    padding-right: 10px;
    .nav-pages {
      margin-top: 0 !important;
      flex-grow: 1;
      justify-content: space-between;
      & > * {
        flex: 1;
      }
      .more-navigation-container {
        text-align: center;
      }
    }
    .more-navigation-element,
    .static-page-link {
      color: $main-blue !important;
      height: 100%;
      display: inline-block;
      transition: 0.2s ease-in;
      min-height: 38px;
      &:hover {
        color: $light-blue !important;
      }
    }
    .static-page-link {
      padding: 6px 16px 5px 16px !important;
    }
    .more-navigation-element {
      padding: 0px 16px 0px 16px !important;
    }
    .active-page {
      background-color: transparent !important;
      color: $light-blue !important;
      &::after {
        display: none !important;
      }
    }

    li:not(:first-child) {
      margin-left: 0px !important;
    }

    .client-picker {
      margin-left: 0px;
      margin-right: 0px;
      border-top-right-radius: 0px;
      border-bottom-right-radius: 0px;
      .search-dropdown {
        border-radius: 0px;
        background-color: transparent;
        border: none;
      }
    }
    .static-page-link-reports-container,
    .static-page-link-analytics-container,
    .analytics-static-link {
      display: none !important;
    }
  }
  .logo-header-scrolled-container {
    display: none;
  }

  .freetools-nav-features {
    .menu-item {
      &:not(:last-child) {
        margin-bottom: 8px !important;
      }
    }
    .static-page-link {
      border-radius: 5px;
      &:hover {
        background-color: $light-blue;
        color: $main-white !important;
      }
    }
  }
}

.header-user-logged {
  .nav-pages {
    .static-page-link {
      padding: 8px 24px !important;
      //border-radius: 50rem;
    }
    .more-navigation-element {
      padding: 0px 24px !important;
    }
    /* .active-page {
      background: $dark-mustard !important;
      color: $main-white !important;
    } */
  }
}

.header-user-logout {
  .nav-pages {
    .active-page {
      &::after {
        border: none;
      }
    }
  }
}

//AE: INTERCOM ADJUSTMENTS
.header-user-logged {
  .main-header-actions-buttons {
    min-width: 224px;
  }
  .nav-pages {
    max-width: calc(100% - 238px);
  }
  .agency-clients-navigation {
    .nav-pages {
      max-width: none;
    }
  }
  .nav-pages {
    margin-top: 5px;
    .static-page-link {
      padding-right: 7px;
      padding-bottom: 5px;
    }
    .more-navigation-element {
      padding-right: 7px;
    }
    .dropdown-item-element {
      padding-right: 30px;
      &::after {
        right: 10px;
      }
    }
  }
}

.header-scrolled {
  background-color: #fff;
  box-shadow: 0 8px 24px rgba(0, 0, 0, 0.13);
  a,
  .btn-login,
  .static-page-link,
  .text-white {
    color: $dark-blue !important;
    &:hover {
      color: $dark-mustard !important;
    }
  }
  .btn-register {
    color: $main-white !important;
    &:hover {
      color: $main-white !important;
    }
  }
  .simple-searchbar {
    border: 1px solid rgba(132, 132, 132, 0.25);
  }
  .logo-header-scrolled-container {
    display: block;
  }
  .header-logo-element {
    display: none !important;
  }
  .nav-pages {
    opacity: 0;
  }
}

.header-favicon {
  .nav-pages {
    opacity: 0;
    animation: opacityNav 0.2s ease-in 1 forwards;
  }
  .logo-header-scrolled {
    height: 22px;
  }
  .logo-part-christmas {
    height: 41px;
  }
}

@keyframes opacityNav {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.header-mobile {
  width: 100%;
  z-index: 102;
  background-color: #fff;
  .logo {
    width: 134px;
  }
}

.header-container {
  max-width: 1357px;
}

.searchbar-header {
  padding: 0.3em 43px 0.3rem 0.9rem;
  position: relative;
  border: 1px solid rgba(132, 132, 132, 0.5);
  color: #fafafa;
  border-radius: 20px !important;
  z-index: 101;
  background: #fff;
  input {
    outline: none;
    border: none;
    width: 100%;
    background: #fff;
    color: #00122b;
  }
  ::placeholder {
    color: rgba(132, 132, 132, 0.8) !important;
  }
  img {
    width: 17px;
    cursor: pointer;
  }
}

.searchbar-header-active {
  background: #f5fafd;
  input {
    background: #f5fafd;
    color: #00122b;
  }
}

.search-results {
  width: 100%;
  position: absolute;
  left: 0px;
  z-index: 100;
  max-height: 300px;
  background-color: #fff;
  border-top: 1px solid transparentize($color: #000000, $amount: 0.8);
  overflow-y: auto;
  box-shadow: 0px 4px 8px -4px rgba(0, 0, 0, 0.41);
  border-radius: 2px;
}

.search-item {
  border-radius: 8px;
  padding: 0.75rem 0.55rem;
  cursor: pointer;
  color: #001c35;
  .icon {
    width: 17px;
    height: 17px;
    background-size: contain;
    background-repeat: no-repeat;
  }
}

.text-item {
  .icon {
    margin-top: 6px;
    background-image: url('#{$cdn-url}icon_header_search.svg');
  }
}

.search-indication {
  font-size: 0.82rem;
  color: #848484;
}

.react-autosuggest__input--focused {
  outline: none;
}

.react-autosuggest__input--open {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

.react-autosuggest__suggestions-container {
  display: none;
}

.react-autosuggest__suggestions-container--open {
  z-index: 101;
  padding: 0.5rem 0;
  display: block;
  position: absolute;
  top: 33px;
  width: 100%;
  background-color: #fff;
  border-bottom-left-radius: 7px;
  border-bottom-right-radius: 7px;
  max-height: 300px;
  overflow-y: auto;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  top: 32px;
  border-bottom: 1px solid #848484;
  border-left: 1px solid #848484;
  border-right: 1px solid #848484;
  width: calc(100% + 2px);
  left: -1px;
  &::before {
    content: '';
    width: 96%;
    //border-top: 1px solid #EBEBEB;
    margin: auto;
    position: absolute;
    height: 2px;
    top: 5px;
    left: 0;
    right: 0;
  }
}

.search-keywordlist-container {
  @extend .react-autosuggest__suggestions-container--open;
  overflow: visible;
  max-height: none;
  padding: 0.5em 0 0 0;
  .search-border-top {
    border-top: 1px solid rgba(212, 212, 212, 0.3);
  }
}

.searchbar-header-dropdown {
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
  border-radius: 5px 5px 0px 0px !important;
  border-top: 1px solid #848484;
  border-left: 1px solid #848484;
  border-right: 1px solid #848484;
  background: #fff;
  input {
    background: #fff;
  }
}

.react-autosuggest__suggestions-list {
  margin: 0;
  padding: 0;
  list-style-type: none;
}

.react-autosuggest__suggestion {
  cursor: pointer;
  padding: 0 0.5rem 0.3rem 0.5rem;
  position: relative;
  .search-message {
    font-size: 0.89rem;
    color: white;
  }
}

.search-message {
  display: none;
}

.react-autosuggest__suggestion--highlighted {
  .search-item {
    background-color: rgba($soft-grey, 0.16);
  }
  .search-message {
    color: rgba(0, 28, 53, 0.7);
  }
}

//SEARCHBOX WORDPRESS
.react-autosuggest__container {
  z-index: 101;
  width: 83%;
}

.close-icon-container {
  width: 28px;
  position: relative;
  display: inline-block;
}

.search-tooltip {
  display: none;
  box-shadow: 0px 2px 4px 2px rgba(0, 0, 0, 0.23);
  position: absolute;
  top: -31px;
  left: -19px;
  padding: 0.3rem 0.8rem;
  color: #4c4c4c;
  background-color: #fff;
  font-size: 0.75rem;
  z-index: 10;
}

.search-boximg-container {
  width: 4%;
}
.searchbox-img {
  cursor: pointer;
  width: 18px;
  margin-top: 5px;
}

.search-actions-container {
  width: 12px;
  img {
    width: 12px;
    margin-top: -3px;
  }
  .bookmark {
    top: 2.4rem;
    right: 0rem;
  }
}

.clear-button {
  font-size: 0.8rem;
  padding: 0 0.5rem 0 0;
  margin-right: 0.5rem;
}

.close-tips {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  background-color: transparent;
  z-index: 100;
}
.star-blue {
  margin-top: -3px;
}

.gimmick-popup {
  align-items: start !important;
  padding-top: 10vh;
  h2 {
    font-size: 1.075em !important;
  }
  .actual-search {
    color: rgba($soft-grey, 0.7);
    cursor: pointer;
  }
}

.gimmick-list {
  max-width: 450px;
  margin: 0 auto;
  li {
    background: url('#{$cdn-url}tick-icon-yellow.svg') left 5px/18px auto no-repeat;
    margin: 0.75em 0;
    padding-left: 30px;
  }
}

.gimmick-list-home {
  max-width: 460px;
  li {
    font-size: 1em !important;
    text-align: left;
  }
}

.search-dropdown {
  width: 38px;
  height: 36px;
  right: 0;
  top: 0;
  border-top-right-radius: 20px;
  border-bottom-right-radius: 20px;
  background: #fff;
  position: absolute;
  border-left: 1px solid rgba(132, 132, 132, 0.2);
  cursor: pointer;
  z-index: 102;
  &::after {
    content: '';
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    border-top: 6px solid #00122b;
    position: absolute;
    top: 17px;
    right: 15px;
  }
}

.search-dropdown-stakeholders {
  border-left: none !important;
  border-radius: 20px !important;
  background: transparent;
  &::after {
    content: '';
    border-top: 6px solid #0094cc;
  }
}

.trial-banner {
  background: #f5bd43;
  color: $main-blue;
  z-index: 1000;
  position: relative;
  p {
    background: url('#{$cdn-url}20220216-clock-draw.svg') 0px 49%/ 17px auto no-repeat;
    padding-left: 25px;
  }
  .left-days {
    color: $main-blue;
    //font-weight: bolder;
  }
  .choose-plan-days {
    color: $light-blue;
    text-decoration: underline;
  }
}

.choose-plan-lessdays {
  background: $light-blue;
  border-radius: 5px;
  color: #fff;
}

.header-banner {
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.08);
  transition: 0.2s ease-in;
  span {
    display: inline-block;
    margin-left: 5px;
  }
  &:hover {
    text-decoration: none;
  }
}

.header-banner {
  span {
    text-decoration: underline;
  }
}

.header-banner-white {
  color: #00122b;
  span {
    color: $hover-blue;
  }
  &:hover {
    color: #00122b;
    background-color: rgba($hover-blue, 0.04);
  }
}

.header-banner-blue {
  color: #fff;
  background: $hover-blue;
  &:hover {
    color: #fff;
    background: rgba($hover-blue, 0.95);
  }
}

.influence-header-box {
  position: absolute;
  top: 38px;
  right: -50px;
  width: 250px;
  z-index: 1000;
  .sidebar-item {
    padding-left: 16px;
  }
  p,
  .sidebar-item {
    color: #022344 !important;
  }
  .sidebar-item.active {
    color: $light-blue !important;
  }
}

.dropdown-white {
  &::after {
    border-top: 6px solid $main-white !important;
    top: 10px !important;
  }
}

.dropdown-white.open {
  &::after {
    border-top: none !important;
    border-bottom: 6px solid $main-white !important;
  }
}

.sidebar-item-name-unread {
  position: relative;
  &::after {
    content: '';
    background: $main-yellow;
    width: 6px;
    height: 6px;
    border-radius: 50%;
    position: absolute;
    top: 1px;
    right: -9px;
  }
}

.item-blocked {
  background: url('#{$cdn-url}padlock.svg') right center / 12px auto no-repeat;
  padding-right: 24px;
}

.intercom-header-box-adjustment {
  a {
    margin: 0;
  }
}

.intercom-header-box-adjustment.active {
  background-color: #ebf7fb;
}

.client-navigation-close-button {
  min-height: 38px;
  transition: 0.2s ease-in;
  padding-left: 1.6em;
  padding-right: 1.6em;
  &:hover {
    background-color: #f8b21b;
  }
  .icon-close-bold {
    margin-left: 12px;
  }
}

@media screen and (min-width: 375px) {
  .quote-text {
    &::after {
      top: -5px;
    }
  }
}

@media screen and (min-width: 576px) {
  .search-boximg-container {
    width: 3%;
  }
  .hashtag-icon-container {
    width: 4%;
  }
  .react-autosuggest__container {
    width: calc(100% - 50px);
  }
}

@media screen and (min-width: 992px) {
  header {
    min-height: 72px;
  }

  .intercom-header-box-adjustment {
    padding-left: 5px;
    &:hover {
      background-color: #f6f6f6;
    }
  }

  .intercom-header-box-adjustment.active:hover {
    background-color: #ebf7fb;
  }

  .main-header {
    transition: 0.2s ease-in;
    max-height: 70px !important;
    .nav-wrapper-container {
      min-height: 43px;
    }

    &.header-user-logout {
      .static-page-link,
      .more-navigation-element {
        padding: 0px 12px !important;
        &.dropdown-item-element {
          padding: 0px 24px 0px 12px !important;
        }
      }
    }

    .nav-pages a,
    .btn-login {
      &:hover {
        color: $dark-mustard !important;
      }
    }
    .nav-pages {
      .book-demo {
        &:hover {
          color: $main-white !important;
        }
      }
    }

    .product-information-list {
      .list-action-button {
        &:hover {
          color: $main-white !important;
        }
      }
    }
    .nav-pages a.dropdown-white {
      &:hover {
        &::after {
          border-top: 6px solid $dark-mustard !important;
        }
      }
    }
    .nav-pages a.dropdown-white.open {
      &:hover {
        &::after {
          border-top: none !important;
          border-bottom: 6px solid $dark-mustard !important;
        }
      }
    }

    .influence-header-box {
      .sidebar-item {
        &:hover {
          color: #022344 !important;
        }
      }
      .sidebar-item.active {
        &:hover {
          color: $light-blue !important;
        }
      }
    }
  }

  .search-dropdown {
    &:hover {
      background-color: rgba(0, 148, 204, 0.08);
    }
  }

  .main-header {
    .searchbar-header {
      width: 340px;
      margin-left: -80px !important;
    }
  }
  .searchbar-header {
    z-index: 103;
  }
  .close-tips {
    z-index: 102;
  }
  .search-message {
    display: inline;
  }

  .ai-assistant-content.active-page {
    &::after {
      border-bottom: 8px solid #fbdedf !important;
    }
  }

  .header-banner {
    span {
      text-decoration: none;
    }
    &:hover {
      span {
        text-decoration: underline;
      }
    }
  }
  .header-user-logged .agency-clients-navigation {
    .static-page-link {
      padding: 7px 12px 6px 12px !important;
    }
    .nav-pages {
      margin-left: 0;
    }
  }
}

@media screen and (min-width: 1024px) {
  .header-user-logged {
    .dropdown-keywords-lists {
      min-width: 350px;
    }
  }

  .react-autosuggest__suggestion--highlighted {
    &::after {
      content: 'Enter';
      position: absolute;
      right: 5%;
      top: 30%;
      font-size: 0.8rem;
      color: #848484;
      width: 76px;
      background-color: #fff;
      text-align: center;
      padding: 0.1rem 0;
      border-radius: 8px;
    }
  }
  .close-icon:hover + .search-tooltip {
    display: block;
  }
  .hashbutton:hover + .search-tooltip {
    display: block;
  }

  .header-user-logged {
    .agency-clients-navigation {
      .static-page-link {
        padding: 7px 28px 6px 28px !important;
      }
    }
  }
}
@media screen and (min-width: 1120px) {
  .main-header {
    .nav-pages {
      max-width: calc(100% - 320px);
    }
    &.header-user-logout {
      .static-page-link,
      .more-navigation-element {
        padding: 0px 24px !important;
      }
    }
    .agency-clients-navigation {
      width: 100%;
      .nav-pages {
        margin-left: 0px !important;
      }
      margin-left: 0px !important;
    }
    .freetools-nav-features {
      .static-page-link {
        padding: 5px 12px !important;
      }
    }
  }
}

@media screen and (min-width: 1200px) {
  .header-user-logged {
    .main-header-actions-buttons {
      min-width: 272px;
    }
    .nav-pages {
      max-width: calc(100% - 336px);
    }
    .agency-clients-navigation {
      .nav-pages {
        max-width: none;
      }
    }
    .dropdown-keywords-lists {
      min-width: 450px;
    }
    .agency-clients-navigation {
      .nav-pages {
        width: 100%;
      }
      .static-page-link {
        flex-grow: 1;
        padding: 7px 0px 6px 0px !important;
      }
      .static-page-link-container {
        text-align: center;
        flex-grow: 1;
      }
    }
  }

  .main-header {
    .searchbar-header {
      width: 340px;
      margin-left: 0px !important;
    }
  }
}

@media screen and (min-width: 1320px) {
  .more-navigation-container {
    display: none;
  }
  .main-header {
    .agency-clients-navigation {
      .static-page-link-reports-container,
      .static-page-link-analytics-container,
      .analytics-static-link,
      .reports-static-link {
        display: block !important;
      }
    }
  }
}
