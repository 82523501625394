.nav-pages {
  a {
    font-size: 1rem;
    color: #001c35;
    white-space: nowrap;
    &:hover {
      color: #0094cc;
      text-decoration: none;
    }
  }
}

.sidebar-nav-pages {
  .nav-pages {
    padding-right: 0 !important;
  }
  .nav-pages-links-container {
    max-height: unset !important;
  }
}
.nav-pages-links-container {
  & li {
    flex-grow: 1;
  }
}
.policy-logo {
  margin-left: -2px;
  width: 128px;
  height: 25px;
}

.christmas-logo {
  height: auto;
  width: 130px;
}

.product-information-list {
  margin-left: -1em;
  [class^='icon-container'] {
    margin-right: 0.65em;
    width: 28px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }
  .icon-search-medium,
  .icon-document-bold {
    font-size: 18px;
  }
  .list-action-button {
    &:hover {
      background-color: $light-blue;
      color: $main-white !important;
    }
  }
  .icon-team,
  .icon-associated-content {
    margin-top: -5px;
  }
  .icon-code {
    margin-top: -6px;
  }
}

.popup-product-features {
  font-size: 1em !important;
}

.product-list-information-modal,
.popup-product-features .modal__overlay {
  background-color: rgba(#525151, 0.5) !important;
  align-items: start !important;
  padding-top: 130px;
}

.product-link {
  a {
    position: relative;
    padding-right: 20px;
    display: inline-block;
    &::after {
      content: '';
      border-left: 5px solid transparent;
      border-right: 5px solid transparent;
      border-top: 6px solid currentColor;
      position: absolute;
      top: 10px;
      right: 0px;
    }
  }
}

.navpage-product {
  padding-right: 0px;
}

.main-header {
  .resize-elements-container {
    .side-left-element {
      width: 280px;
    }
    .main-content {
      width: calc(100% - 280px);
    }
  }
  .nav-pages {
    li {
      &:not(:first-child) {
        margin-left: 10px;
      }
    }
    .nav-pages-more-btn {
      margin-left: 10px;
    }
  }
  .sidebar-nav-pages {
    .nav-pages {
      li {
        &:not(:first-child) {
          margin-left: 0;
        }
      }
    }
  }
}

.admin-navbar {
  overflow-x: auto;
  & .admin-sidebar-item {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 8px 12px !important;
    height: 100%;
    & .political-updates-nav-icon {
      margin-bottom: 4px;
    }
    & span {
      text-align: center;
    }
    &:has(.icon-container-suitcase) {
      min-width: 110px;
    }
    .icon-warning-circle-thick {
      display: inline-block;
      transform: translateY(3px);
    }
  }
}

@media screen and (min-width: 992px) {
  .nav-pages {
    margin-left: -24px;
  }
  .header-user-logout {
    .nav-pages {
      margin-left: 0px;
    }
  }
  .main-header {
    .resize-elements-container {
      .side-left-element {
        width: 280px;
      }
      .side-left-element-new-agency {
        width: 176px;
      }
      .main-content {
        width: calc(100% - 280px);
      }
    }
  }

  .nav-pages-additional-links {
    padding-right: 20px !important;
    li {
      &:not(:first-child) {
        margin-left: 13px !important;
      }
    }
    a {
      font-size: 0.92em !important;
    }
  }

  .header-user-logout,
  .main-header {
    .simple-searchbar-open {
      max-width: 290px;
    }
  }

  .admin-navbar {
    overflow-x: auto;
    & .admin-sidebar-item {
      display: block !important;
      padding: 7px 16px 7px 38px !important;
      & span {
        text-align: start !important;
      }
    }
  }

  .nav-pages-additional-links {
    padding-right: 20px !important;
    li {
      &:not(:first-child) {
        margin-left: 13px !important;
      }
    }
    a {
      font-size: 0.92em !important;
    }
  }

  .header-user-logout,
  .header-logout {
    .simple-searchbar-open {
      max-width: 290px;
    }
  }
}

@media screen and (min-width: 1050px) {
  .navpage-product {
    padding-right: 24px;
  }
  .header-user-logout {
    .simple-searchbar-open {
      width: 350px;
    }
  }
}

@media screen and (min-width: 1050px) {
  .header-user-logout {
    .simple-searchbar-open {
      width: 350px;
    }
  }
}

@media screen and (min-width: 1200px) {
  .nav-pages {
    // padding-right: 0px !important;
  }
  .nav-pages-additional-links {
    li {
      &:not(:first-child) {
        margin-left: 20px !important;
      }
    }
    a {
      font-size: 1em !important;
    }
  }
}

@media screen and (min-width: 1280px) {
  .main-header {
    .resize-elements-container {
      .side-left-element {
        width: 280px;
      }
      .main-content {
        width: calc(100% - 280px);
      }
    }
  }
}

@media screen and (min-width: 1300px) {
  .nav-pages-additional-links {
    li {
      &:not(:first-child) {
        margin-left: 25px !important;
      }
    }
  }
}

@media screen and (min-width: 1366px) {
  .main-header {
    .nav-pages {
      li {
        &:not(:first-child) {
          margin-left: 25px;
        }
      }
    }
    .nav-pages-additional-links {
      li {
        &:not(:first-child) {
          margin-left: 25px !important;
        }
      }
    }
  }
}
