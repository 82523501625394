.explanations {
  display: flex;
  border-radius: 0px 0px 5px 5px;
  p {
    color: rgba(0, 18, 43, 0.8) !important;
    font-size: 0.85em !important;
  }
  .hand-picked-item {
    margin-top: 12px;
  }
}

.rendering-items-container {
  margin-top: 16px;
  width: 100%;
  padding-right: 80px;
}

.explanations-handpicked {
  display: block;
}

.hand-picked-item {
  font-size: 0.75em !important;
}

.explanation-tag {
  color: #00122b;
  border: 1px solid rgba(212, 212, 212, 0.6);
  border-radius: 3px;
  display: inline-block;
  padding: 8px 16px 8px 6px;
  margin-right: 0.5em;
  text-align: center;
  position: relative;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  line-height: normal;
  max-width: 110px;
  cursor: pointer;
  font-size: 0.98em;
  &:not(:first-child) {
    margin-left: 0.5em;
  }
  &:hover {
    background-color: rgba(244, 239, 237, 0.6);
  }
}

.explanation-tag-inactive {
  &:hover {
    background-color: white;
  }
}

.explanation-container {
  display: inline-block;
  margin-bottom: 0;
  line-height: 0;
}

.more-tags-text {
  margin: 0 0.35em;
  font-size: 0.9625em;
}

.explanation-tooltip {
  display: none;
  font-style: normal;
  position: absolute;
  width: auto;
  white-space: nowrap;
  background: rgba(255, 255, 255, 0.8);
  border: 1px solid rgba(212, 212, 212, 0.6);
  z-index: 100;
  border-radius: 3px;
  left: 50%;
  top: -30px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.15);
  padding: 0.35em 0.85em;
  strong {
    color: $hover-blue;
  }
}

.less-tags {
  background: #fff;
  border-radius: 5px 5px 0px 0px;
  font-weight: 700;
  cursor: pointer;
  .icon-arrow-down {
    font-size: 5px;
    transform: rotate((180deg));
  }
}

.more-text {
  font-size: 0.85em;
  color: #00122b;
  cursor: pointer;
  position: relative;
  padding-right: 1.15em;
  .icon-arrow-down {
    position: absolute;
    right: 0;
    top: 7px;
    font-size: 5px;
  }
}

.more-tags-container {
  position: absolute;
  top: -4px;
  background: #f6f6f6;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.15);
  border-radius: 5px;
  background: #fff;
  z-index: 1000;
  .explanation-container,
  .explanation-tag {
    text-align: left;
    width: 100%;
    display: block;
  }
  .explanation-tag {
    margin-bottom: 0.5em;
  }
}

.relevance-bars {
  display: inline-block;
  margin-right: 0.55em;
  vertical-align: middle;
  margin-top: 1px;
  span {
    display: inline-block;
    width: 3px;
    border-radius: 1px;
    background: #00122b;
    margin-right: 2px;
  }
}

.relevance-bars-hover {
  margin-top: 1px;
  span {
    background: #fff;
  }
}

.hover-explanation-strong {
  .relevance-bars-3 {
    background: rgba(255, 255, 255, 0.4) !important;
  }
}
.hover-explanation-moderate {
  .relevance-bars-3,
  .relevance-bars-2 {
    background: rgba(255, 255, 255, 0.4) !important;
  }
}

.relevance-bars-1 {
  height: 7px;
}
.relevance-bars-2 {
  height: 10px;
}
.relevance-bars-3 {
  height: 13px;
}

.tags-tooltip {
  font-size: 0.8125em !important;
  border-radius: 5px !important;
  padding: 7px 15px 5px 15px !important;
  z-index: 1002 !important;
}

.explanation-strong {
  .relevance-bars-3 {
    background: rgba(0, 18, 43, 0.3) !important;
  }
}

.explanation-moderate {
  .relevance-bars-3,
  .relevance-bars-2 {
    background: rgba(0, 18, 43, 0.3) !important;
  }
}

.tag-keyword-list {
  padding-left: 16px;
  max-height: 34px;
  .icon-clock {
    display: inline-block;
    transform: translateY(1px);
    margin-right: 6px;
  }
  .icon-topics {
    display: inline-block;
    transform: translateY(1.5px);
    margin-right: 8px;
    font-size: 14px;
  }
}

.tag-keyword-list-current-topic {
  background-color: $main-white !important;
}

.tag-keyword-list-live {
  color: $medium-sea-green;
  max-width: none !important;
  &:hover {
    background-color: transparent !important;
  }
  &.normal {
    color: $main-blue;
  }
  .element-record {
    border-radius: 50%;
    display: inline-block;
    vertical-align: middle;
    margin-top: -3px;
    margin-right: 8px;
    width: 16px;
    height: 16px;
    border: 1px solid $medium-sea-green;
    position: relative;
    background: transparent;
    box-shadow: 0 0 0 rgba(0, 0, 0, 1);
    animation: anim-vibrate 3s infinite;
    &:after {
      content: '';
      position: absolute;
      width: 8px;
      height: 8px;
      top: 3px;
      left: 3px;
      border-radius: 50%;
      background: $medium-sea-green;
    }

    @keyframes anim-vibrate {
      0% {
        box-shadow: 0 0 0 0 rgba($medium-sea-green, 0.65);
        transform: scale(0.95);
      }

      70% {
        box-shadow: 0 0 0 0.75rem rgba(0, 0, 0, 0);
        transform: scale(1);
      }

      100% {
        box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
        transform: scale(0.95);
      }
    }
  }

  &.ended {
    color: #b40a37 !important;
    .element-record {
      display: none;
    }
  }
}

.tag-keyword-list-watch {
  border-color: $light-blue;
  color: $light-blue;
  transition: 0.2s ease-in;
  .icon-single-play {
    margin-right: 8px;
  }
}

.feedback-item-option-container {
  padding-left: 28px;
}

.feedback-item-popup {
  padding-top: 11%;
  align-items: start !important;

  .radio-button {
    label {
      height: auto !important;
      line-height: 1.3;
    }
  }
  .frequency-dropdown {
    top: 34px;
  }

  .large-container {
    label {
      line-height: 1;
      margin-top: 0px;
      width: 100%;
    }
  }
}

.button-thumbs-down {
  margin-left: 5px;
  .icon-thumbs-down {
    margin-left: -3px;
    color: #7f8894;
  }
}

.button-create-report {
  .icon-document {
    color: #7f8894;
  }
}

.button-thumbs-down-default {
  cursor: default !important;
}

.item-actions-button-feedback {
  margin-top: 1px;
}

.main-update-content-container {
  .button-thumbs-down {
    margin-right: 0px;
    width: 30px;
    height: 30px;
    min-width: 30px;
  }
  .icon-thumbs-down,
  .fa-spin {
    margin-left: -1px;
    font-size: 16px;
  }
}

.item-share-feedback-container {
  padding: 0.75em 1em 0.75em 2em;
}

.item-share-feedback-container-single-button {
  padding: 0.5em 1em 0.5em 2em;
}

.feedback-popup-remove-check {
  .checkbox-item {
    display: flex;
    label {
      display: block;
      padding-left: 19px !important;
      height: auto;
      line-height: 1.5;
      margin-top: -4px;
    }
  }
}

.feedback-popup-remove-check {
  .checkbox-item {
    display: flex;
    label {
      display: block;
      padding-left: 19px !important;
      height: auto;
      line-height: 1.5;
      margin-top: -4px;
      &::before {
        border: 2px solid $main-white !important;
      }
    }
    input[type='checkbox'] {
      &:checked {
        + label:after {
          transform: translate(0.335em, 0.365em) rotate(-45deg);
          width: 0.48em;
          height: 0.24em;
        }
      }
    }
  }
}

@media screen and (min-width: 408px) {
  .hand-picked-item {
    font-size: 0.85em !important;
  }
}

@media screen and (min-width: 992px) {
  .item-share-feedback-container {
    width: calc(100% + 0.5em);
    margin-left: -0.5em;
  }

  .explanation-tag {
    max-width: 167px;
  }

  .feedback-popup-remove-check {
    .checkbox-item {
      &:hover {
        label {
          &::before {
            background-color: $main-white;
          }
        }
        input[type='checkbox'] {
          &:checked {
            + label:before {
              background: $main-blue;
            }
          }
        }
      }
    }
  }

  .button-thumbs-down,
  .button-create-report {
    &:hover {
      background-color: rgba($light-blue, 0.08);
    }
  }
  .button-thumbs-down-default {
    &:hover {
      background: none;
    }
  }
}
