.sentiment-page-container {
  max-width: 1440px;
  margin: 0 auto;
  position: relative;
  height: 100%;
}

.big-ben-container {
  position: absolute;
  right: 2.5em;
  top: 32px;
  height: calc(100% - 32px);
  display: none;
  &::before {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    height: 50%;
    width: 100%;
    background: #a76248;
    z-index: 0;
  }
}
.event-list-empty-big-ben {
  .big-ben-container {
    position: unset;
  }
}

.big-ben-background {
  background: url('#{$cdn-url}20220926-bigben-background.svg') center 0 / 100% auto no-repeat;
  width: 115px;
  height: 100%;
  position: relative;
  z-index: 1;
}

.sentiment-page-filters {
  .chart-type-filter {
    margin-left: 0 !important;
    margin-right: 1em;
  }
}

.sentiment-page-main-button {
  display: inline-block;
  padding: 8px 20px 8px 40px;
  color: $main-blue;
  background-position: 14px 13px;
  background-size: 17px auto;
  background-repeat: no-repeat;
  &:hover {
    color: $main-blue;
    text-decoration: none;
  }
}

.sentiment-button-faqs {
  background-image: url('#{$cdn-url}20220926-faqs-icon.svg');
}

.sentiment-button-new-search {
  background-image: url('#{$cdn-url}20220926-back-arrow-icon.svg');
}

.sentiment-button-share {
  background-image: url('#{$cdn-url}20210415-share-stakeholder-icon.svg');
}

.sentiment-button-faqs,
.sentiment-button-share {
  text-indent: -1000px;
  overflow: hidden;
  padding-right: 0;
}

.sentiment-button-faqs {
  background-position: 11px 11px;
  background-size: 20px auto;
}

.sentiment-button-share {
  background-position: 12px 13px;
  background-size: 18px auto;
}

.sentiment-button-try {
  padding-top: 8px;
  padding-bottom: 8px;
}

.sentiment-searchbar-section {
  background-image: url('#{$cdn-url}20221003-sentiment-bars.svg');
  background-size: 1415px auto;
  background-repeat: repeat-x;
  background-position: center 12vh;
}

.sentiment-page-searchbar {
  padding-top: 45px;
  .simple-searchbar-open {
    display: flex;
    height: 40px;
  }
  .main-search-icon {
    margin-top: -1px;
  }
}

.clock-background {
  width: 135px;
  height: 117px;
  position: absolute;
  background: #ac8474;
  left: -10px;
  top: 120px;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);
}

.sentiment-page-graph-container {
  .preloader-min-height {
    min-height: 360px;
  }
}

/* CLOCK STYLES */
.clock {
  width: 90px;
  height: 90px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #fff;
  background-size: cover;
  border-radius: 50%;
  position: absolute;
  top: 13px;
  left: 22px;
  box-shadow: 0px 1px 5px rgba(0, 0, 0, 0.25);
}

.clock::before {
  content: '';
  position: absolute;
  width: 10px;
  height: 10px;
  border: 1px solid $main-white;
  background: $main-blue;
  border-radius: 50%;
  z-index: 100000;
}

.hour,
.min,
.sec {
  position: absolute;
}

.hour,
.hr {
  width: 55px;
  height: 55px;
}

.min,
.mn {
  width: 70px;
  height: 70px;
}
.sec,
.sc {
  width: 70px;
  height: 70px;
}

.hr,
.mn,
.sc {
  display: flex;
  justify-content: center;
  position: absolute;
  border-radius: 50%;
}

.hr::before {
  content: '';
  position: absolute;
  width: 5px;
  height: 26px;
  background: $main-blue;
  z-index: 10;
  border-radius: 6px 6px 0 0;
}

.mn::before {
  content: '';
  position: absolute;
  width: 5px;
  height: 40px;
  background: rgba($main-blue, 0.8);
  z-index: 11;
  border-radius: 6px;
}

.sc::before {
  content: ' ';
  position: absolute;
  width: 2px;
  height: 50px;
  background: rgba($main-blue, 0.6);
  z-index: 12;
  border-radius: 6px 6px 0 0;
}

.rate-limit-popup {
  .modal-close-button {
    background-color: rgba($main-blue, 0.7) !important;
    color: #fff;
    & .icon-close {
      display: block;
      transform: translateY(1px);
    }
    &:hover {
      background-color: rgba($main-blue, 1) !important;
    }
  }
}

.sentiment-screenshot {
  max-width: 565px;
}

.sentiment-list-item {
  padding: 8px 20px 8px 62px;
  margin-bottom: 12px;
  border-radius: 5px;
  background: url('#{$cdn-url}20221003-white-tickbox.svg') 16px 6px no-repeat;
}

.sentiment-list-item-selected {
  background-color: $background-grey;
  color: $main-blue;
  background-image: url('#{$cdn-url}202210032-blue-tickbox.svg');
}

.sentiment-action-button {
  background-color: $main-blue;
  color: $background-grey;
  background-image: url('#{$cdn-url}20221003-sign-up-left-arrow.svg');
}

.sentiment-political-box {
  width: 100%;
}

.analytics-rate-limit-popup {
  background-color: rgba(0, 18, 43, 0.6) !important;
  .modal-close-button {
    background-color: rgba($main-blue, 0.7) !important;
    color: $main-white;
  }
}

.sentiment-searchbar-title {
  font-size: 2em;
}

.sentiment-page-subtitle {
  font-size: 1.15em;
}

.sentiment-main-section {
  height: 680px;
}

@media screen and (min-width: 992px) {
  .sentiment-searchbar-title {
    font-size: 2.5em;
  }

  .sentiment-political-box {
    width: auto;
  }

  .sentiment-list-item {
    border-radius: 5px 0px 0px 5px;
  }

  .sentiment-page-subtitle {
    max-width: 550px;
    font-weight: 300;
    font-size: 1.25rem;
  }

  .sentiment-button-faqs,
  .sentiment-button-new-search {
    &:hover {
      background-color: $hovered-blue !important;
    }
  }

  .sentiment-button-share {
    transition: 0.2s ease-in;
    &:hover {
      background-color: rgba(255, 211, 114, 1) !important;
    }
  }

  .results-page.sentiment-button-share {
    &:hover {
      background-color: $hovered-blue !important;
    }
  }

  .sentiment-action-button {
    &:hover {
      background-color: #404d60 !important;
    }
  }

  .sentiment-button-faqs,
  .sentiment-button-share {
    text-indent: 0px;
    padding-right: 20px;
    background-position: 14px 13px;
    background-size: 17px auto;
  }
  .sentiment-searchbar-section {
    background-position: center 14vh;
  }
}

@media screen and (min-width: 1200px) {
  .big-ben-container {
    display: block;
  }

  .sentiment-page-graph-container {
    width: calc(100% - 200px);
  }

  .sentiment-page-searchbar {
    padding-top: 65px;
    .simple-searchbar-open {
      max-width: 500px;
    }
  }
}

@media screen and (min-height: 1000px) {
  .sentiment-searchbar-section {
    background-position: center 70%;
  }
}
