.current-page-parent a {
  color: $main-yellow !important;
}
.menu-item-has-children {
  position: relative;
  padding-right: 0px;
  > a {
    @extend .dropdown-item-element;
    @extend .dropdown-item-element-10;
  }
  .sub-menu {
    li {
      margin: 0 !important;
    }
    a {
      display: block;
      padding: 5px 16px;
      margin: 0 !important;
      width: 100%;
      color: $main-blue !important;
      @extend .list-action-button;
    }
    [class^='icon-'] {
      display: inline-block;
      margin-right: 10.4px;
      width: 22px;
    }
  }
}

.submenu-item {
  color: $main-blue !important;
  padding: 5px 12px;
}

.static-page-link {
  &.open,
  &.active {
    color: $main-yellow !important;
  }
  &:hover {
    color: $main-yellow !important;
  }
}

.navepage-product-mobile {
  .dropdown-item-element {
    &::after {
      top: 14px;
    }
  }
}

@media screen and (min-width: 1050px) {
  .menu-item-has-children {
    padding-right: 24px;
  }
}
