.share-text {
  color: rgba(0, 18, 43, 0.6);
  font-size: 0.9em;
}

.share-image {
  margin: 0 4px;
  background-position: center;
  background-image: url('#{$cdn-url}share-icon-v1.svg');
  background-size: 16px auto;
  background-repeat: no-repeat;
  width: 30px;
  height: 30px;
  border-radius: 50%;
}

.share-parliamentary-content {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  margin: 0 4px;
  .icon-twitter-x-circle,
  .icon-share-link {
    display: block;
    margin-left: -1px;
    color: rgb(0, 18, 43);
    font-size: 18px;
  }
  .icon-copy,
  .icon-share-video {
    display: inline-block;
    vertical-align: middle;
    margin-top: -2px;
  }
  .icon-share-video {
    margin-top: -1px;
  }
}

.share-twitter-icon {
  background-image: url('#{$cdn-url}twitter-x.svg');
  background-size: 30px auto;
  margin-right: 0px;
}

.react-share__ShareButton {
  &:focus {
    border: none;
    outline: none;
  }
}

.link-copied {
  position: absolute;
  white-space: nowrap;
  padding: 0.15em 0.65em;
  left: -90px;
  display: inline-block;
  background: #ffffff;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.15);
  border-radius: 5px;
  img {
    display: inline-block;
    vertical-align: middle;
    margin-top: -2px;
  }
}

.disabled-share-image {
  background-image: url('#{$cdn-url}share-icon-disabled.svg');
}

.item-actions-share.disabled-share-twitter {
  background-position: 8px 9px !important;
  color: rgba($main-blue, 0.6) !important;
}

.disabled-share-twitter {
  background-image: url('#{$cdn-url}twitter-x.svg') !important;
}

@media screen and (min-width: 992px) {
  .share-image,
  .share-parliamentary-content,
  .item-actions-save-content,
  .content-header-actions .button-thumbs-down,
  .button-create-report,
  .circle-btn-with-hover {
    transition: 0.2s ease-in;
    &:hover {
      background-color: rgba(0, 148, 204, 0.08) !important;
    }
  }
  .disabled-share-image {
    &:hover {
      background-color: rgba(244, 239, 237, 0.6) !important;
    }
  }
}
