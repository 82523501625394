@import './DropdownKeywordsLists';
@import './NavPages';

.btn-header-intercom-adjustment {
  display: none;
}

.button-my-topics {
  width: 35px;
  height: 35px;
  border-radius: 50% !important;
  color: $main-blue;
  background: $main-white !important;
  text-align: center;
  .icon-topics-container {
    padding-top: 2px;
    margin-left: -6px;
  }
  &:hover {
    color: $main-blue !important;
    background-color: $hovered-blue !important;
  }
  .text {
    display: none;
  }
}

.header-tooltip {
  @extend .pricing-feature-tooltip;
  padding: 5px 10px !important;
  background-color: #00122b !important;
  color: #fff !important;
}
.tooltip-width {
  max-width: 250px !important;
}

@for $i from 200 through 300 {
  @if $i % 10 == 0 {
    .tooltip-width-#{$i} {
      max-width: #{$i}px !important;
    }
  }
}

.edit-icon-analytics-tooltip {
  z-index: 100001 !important;
  padding: 5px 10px !important;
  background-color: #00122b !important;
  color: #fff !important;
  border-radius: 5px !important;
}

.send-email-trial-tooltip {
  background-color: #fff;
  border: 1px solid rgba(212, 212, 212, 0.5);
  position: absolute;
  z-index: 99999;
  top: 50px;
  width: 260px;
  left: -135px;
  border-radius: 5px;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.15);
  p {
    color: #00122b;
    font-size: 15px;
  }
  .trial-tooltip-separator {
    height: 1px;
    background-color: rgba(212, 212, 212, 0.5);
  }
  & span {
    z-index: 1;
    cursor: pointer;
  }

  &:hover {
    visibility: visible !important;
    opacity: 1 !important;
  }
  &:before {
    content: ' ';
    position: absolute;
    &:hover {
      text-decoration: none;
    }
    display: block;
    background-color: #fff;
    right: 66px;
    top: -8px;
    border: 1px solid rgba(212, 212, 212, 0.5);
    width: 14px;
    height: 14px;
    transform: rotate(45deg);
    border-bottom: transparent;
    border-right: transparent;
  }
  &:after {
    content: '';
    position: absolute;
    display: block;
    width: 120%;
    height: 120%;
    left: -10%;
    top: -10%;
    z-index: 0;
  }
}

@media screen and (min-width: 1050px) {
  .btn-header-intercom-adjustment {
    display: block;
  }
}

@media screen and (min-width: 1150px) {
  .button-my-topics {
    width: auto;
    border-radius: 20px !important;
    background-position: 0.9em 7px !important;
    max-height: 35px;
    padding-top: 4px;
    overflow: visible;
    text-indent: 0px;
    .text {
      display: inline-block;
    }
    .icon-topics-container {
      font-size: 18px;
      margin-left: 0px;
      padding-top: 3px;
      margin-right: 10px;
      display: inline-block;
    }
  }
}
