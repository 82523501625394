.events-list-title {
  font-size: 3.125rem;
}
.events-list-padding {
  padding-left: 115px !important;
}
.event-item-live-badge {
  border: 1px solid #fff;
}
.free-tools-container {
  & .items-list li {
    border-radius: 0 5px 5px 0;
    @media screen and (min-width: 1366px) {
      border-radius: 5px;
    }
  }
}
.free-tools-title {
  transition: margin-top 500ms;
}
.search-events-input {
  max-height: 37.5px;
}
.selected-event-bodies-container {
  word-wrap: break-word;
  & > .paragraph-p1:last-of-type {
    margin-bottom: 80px !important;
  }
}
.scrollbar-top-fade {
  &::before {
    content: '';
    display: block;
    position: absolute;
    height: 100px;
    width: 100%;
    z-index: 1;
    background: linear-gradient(180deg, white 0%, transparent 100%);
    pointer-events: none;
  }
}
.scrollbar-bottom-fade {
  &::after {
    content: '';
    display: block;
    position: absolute;
    height: 100px;
    width: 100%;
    z-index: 1;
    background: linear-gradient(0deg, white 0%, transparent 100%);
    pointer-events: none;
    bottom: 0;
  }
}
.element-record-past-event {
  & > .icon-record {
    display: inline-block;
    transform: translateY(1px);
  }
}

.events-list-empty-content-container {
  max-width: 771px;
  .event-list-item {
    cursor: default !important;
    &:hover {
      box-shadow: none;
    }
  }
}
@media screen and (min-width: 992px) {
  .free-tools-container {
    & > div {
      max-width: calc(50% - 20px);
      min-width: calc(50% - 20px);
    }
  }
}

@media screen and (min-width: 1366px) {
  .parliament-event-list-container {
    padding-left: 2.5rem;
  }
  .events-list-empty-content-container {
    max-width: 1000px;
  }
}
